table,
td {
  margin: 0;
}

.PF {
  @media (max-width: 480px) {
    display: none !important;
    visibility: hidden;
  }

  // S - Tablet
  @media (max-width: 481px) and (max-width: 768px) {
    display: none;
  }
}

.ProjectFooter {
  margin-top: 20px;
  margin-bottom: 40px;

  a {
    color: #fff !important;
    text-decoration: underline;
  }

  h2,
  h3,
  h4 {
    font-size: 14px !important;
    font-weight: normal !important;
  }

  //border: 1px solid rgba(225,255,255,0.2);

  .project-footer-title {
    padding: 10px;
    border: 1px solid rgba(225, 255, 255, 0.2);
  }

  .shaded {
    padding: 20px;
    p {
      opacity: 0.6;
    }
  }

  // XS - Phone

  // M - Tablet/Laptop
  @media (max-width: 769px) and (max-width: 1024px) {
  }

  // L - High Res
  @media (min-width: 1025px) and (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
