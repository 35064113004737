.LogoType {
  font-family: var(--main-text-bold);
  font-size: var(--main-text-bold-100);

  &[data-object-type='logo'] {
    float: left;
  }

  &[data-object-type='slogan'] {
    float: right;
  }

  &[data-size='small'] {
    font-size: var(--main-text-bold-40);
  }

  // XS - Phone
  @media (max-width: 480px) {
    font-size: var(--main-text-bold-40);

    &[data-size='small'] {
      font-size: var(--main-text-bold-40);
    }
  }

  // S - Tablet
  @media (max-width: 768px) {
    font-size: var(--main-text-bold-40);

    &[data-size='small'] {
      font-size: var(--main-text-bold-20);
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
