.Footer {
  background-color: var(--bg-black);
  padding: 5%;
  //background-position: top center;
  //background-size: 100%;
  //min-height: 400px;
  //background-repeat: no-repeat;

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
