body {
  margin: map-get($page, 'margin');
  padding: map-get($page, 'padding');
  background-color: var(--bg- + map-get($page, 'backgroundColor'));
  font-family: 'main-text-regular', sans-serif;
}

hr {
  opacity: 0;
  margin: 10px;
}
