.ProjectIndicator {
  font-family: var(--main-text-bold);
  font-size: var(--main-text-bold-80);

  display: flex;

  .indicator {
    width: 50px;
    text-align: center;
  }

  // XS - Phone
  @media (max-width: 480px) {
    font-size: var(--main-text-bold-40);
    .indicator {
      width: 30px;
    }
  }

  // S - Tablet
  @media (max-width: 768px) {
    font-size: var(--main-text-bold-50);
    .indicator {
      width: 35px;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
