.Project {
  padding: 1.5%;
  padding-left: 5%;
  padding-right: 5%;
  border-top: 1px solid var(--bg-black);
  transition: var(--all-transition);

  .project-info-bar {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  &:last-child {
    border-bottom: 1px solid var(--bg-black);
  }

  &:hover {
    background-color: var(--bg-black);
    color: var(--fg-white);
  }
  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}

.open {
  background-color: var(--bg-black) !important;
  color: var(--fg-white) !important;
}
