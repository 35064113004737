@charset "UTF-8";
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
  font-family: 'main-text-regular';
  src: url('../fonts/n27-regular-webfont.woff') format('woff'),
    url('../fonts/n27-regular-webfont.woff2') format('woff2');
}
/*! main-text-bold */
@font-face {
  font-family: 'main-text-bold';
  src: url('../fonts/n27-bold-webfont.woff') format('woff'),
    url('../fonts/n27-bold-webfont.woff2') format('woff2');
}
/*! main-text-link */
@font-face {
  font-family: 'main-text-link';
  src: url('../fonts/n27-regular-webfont.woff') format('woff'),
    url('../fonts/n27-regular-webfont.woff2') format('woff2');
}
/*! heading */
@font-face {
  font-family: 'heading';
  src: url('../fonts/n27-regular-webfont.woff') format('woff'),
    url('../fonts/n27-regular-webfont.woff2') format('woff2');
}
/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  /*! Background Color */
  /*! white */
  --bg-white: #fff;
  /*! black */
  --bg-black: #000;
  /*! white-muted */
  --bg-white-muted: rgba(255, 255, 255, 0.6);
  /*! black-muted */
  --bg-black-muted: rgba(0, 0, 0, 0.6);
  /*! Foreground Color */
  /*! white */
  --fg-white: #fff;
  /*! black */
  --fg-black: #000;
  /*! white-muted */
  --fg-white-muted: rgba(255, 255, 255, 0.6);
  /*! black-muted */
  --fg-black-muted: rgba(0, 0, 0, 0.6);
  /** FONT **/
  --main-text-regular: 'main-text-regular';
  --main-text-bold: 'main-text-bold';
  --main-text-link: 'main-text-link';
  /** FONT-SIZES **/
  --main-text-regular-9: 0.5625rem;
  --main-text-regular-10: 0.625rem;
  --main-text-regular-12: 0.75rem;
  --main-text-regular-14: 0.875rem;
  --main-text-regular-16: 1rem;
  --main-text-regular-18: 1.125rem;
  --main-text-regular-20: 1.25rem;
  --main-text-bold-12: 0.75rem;
  --main-text-bold-14: 0.875rem;
  --main-text-bold-16: 1rem;
  --main-text-bold-18: 1.125rem;
  --main-text-bold-20: 1.25rem;
  --main-text-bold-30: 1.875rem;
  --main-text-bold-40: 2.5rem;
  --main-text-bold-44: 2.75rem;
  --main-text-bold-50: 3.125rem;
  --main-text-bold-60: 3.75rem;
  --main-text-bold-52: 3.25rem;
  --main-text-bold-70: 4.375rem;
  --main-text-bold-80: 5rem;
  --main-text-bold-90: 5.625rem;
  --main-text-bold-100: 6.25rem;
  --main-text-link-12: 0.75rem;
  --main-text-link-14: 0.875rem;
  --main-text-link-16: 1rem;
  --all-transition: all 0.4s ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-white);
  font-family: 'main-text-regular', sans-serif;
}

hr {
  opacity: 0;
  margin: 10px;
}

/** UTILITY: Colors **/
/*! Background Color */
/*! white */
.bg-white {
  background-color: var(--bg-white);
}

/*! black */
.bg-black {
  background-color: var(--bg-black);
}

/*! white-muted */
.bg-white-muted {
  background-color: var(--bg-white-muted);
}

/*! black-muted */
.bg-black-muted {
  background-color: var(--bg-black-muted);
}

/*! Foreground Color */
/*! white */
.fg-white {
  color: var(--fg-white);
}

/*! black */
.fg-black {
  color: var(--fg-black);
}

/*! white-muted */
.fg-white-muted {
  color: var(--fg-white-muted);
}

/*! black-muted */
.fg-black-muted {
  color: var(--fg-black-muted);
}

.main-text-regular-9 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-9);
}

.main-text-regular-10 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-10);
}

.main-text-regular-12 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-12);
}

.main-text-regular-14 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-14);
}

.main-text-regular-16 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-16);
}

.main-text-regular-18 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-18);
}

.main-text-regular-20 {
  font-family: 'main-text-regular';
  font-size: var(--main-text-regular-20);
}

.main-text-bold-12 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-12);
}

.main-text-bold-14 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-14);
}

.main-text-bold-16 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-16);
}

.main-text-bold-18 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-18);
}

.main-text-bold-20 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-20);
}

.main-text-bold-30 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-30);
}

.main-text-bold-40 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-40);
}

.main-text-bold-44 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-44);
}

.main-text-bold-50 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-50);
}

.main-text-bold-60 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-60);
}

.main-text-bold-52 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-52);
}

.main-text-bold-70 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-70);
}

.main-text-bold-80 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-80);
}

.main-text-bold-90 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-90);
}

.main-text-bold-100 {
  font-family: 'main-text-bold';
  font-size: var(--main-text-bold-100);
}

.main-text-link-12 {
  font-family: 'main-text-link';
  font-size: var(--main-text-link-12);
}

.main-text-link-14 {
  font-family: 'main-text-link';
  font-size: var(--main-text-link-14);
}

.main-text-link-16 {
  font-family: 'main-text-link';
  font-size: var(--main-text-link-16);
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}

.float-right {
  justify-content: flex-end;
}

.float-start {
  justify-content: flex-start;
}

.float-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

h1 {
  font-family: 'heading';
  font-size: 2.625rem;
}

h2 {
  font-family: 'heading';
  font-size: 2rem;
}

h3 {
  font-family: 'heading';
  font-size: 1.75rem;
}

h4 {
  font-family: 'heading';
  font-size: 1.625rem;
}

h5 {
  font-family: 'heading';
  font-size: 1.5rem;
}

h6 {
  font-family: 'heading';
  font-size: 1.375rem;
}

.LogoType {
  font-family: var(--main-text-bold);
  font-size: var(--main-text-bold-100);
}
.LogoType[data-object-type='logo'] {
  float: left;
}
.LogoType[data-object-type='slogan'] {
  float: right;
}
.LogoType[data-size='small'] {
  font-size: var(--main-text-bold-40);
}
@media (max-width: 480px) {
  .LogoType {
    font-size: var(--main-text-bold-40);
  }
  .LogoType[data-size='small'] {
    font-size: var(--main-text-bold-40);
  }
}
@media (max-width: 768px) {
  .LogoType {
    font-size: var(--main-text-bold-40);
  }
  .LogoType[data-size='small'] {
    font-size: var(--main-text-bold-20);
  }
}
.ProjectIndicator {
  font-family: var(--main-text-bold);
  font-size: var(--main-text-bold-80);
  display: flex;
}
.ProjectIndicator .indicator {
  width: 50px;
  text-align: center;
}
@media (max-width: 480px) {
  .ProjectIndicator {
    font-size: var(--main-text-bold-40);
  }
  .ProjectIndicator .indicator {
    width: 30px;
  }
}
@media (max-width: 768px) {
  .ProjectIndicator {
    font-size: var(--main-text-bold-50);
  }
  .ProjectIndicator .indicator {
    width: 35px;
  }
}
.ParagraphText {
  font-size: var(--main-text-regular-14);
  font-family: var(--main-text-regular);
  line-height: var(--paragraph-line-heigth);
  width: 50%;
}
.ParagraphText[data-object-type='regular'] {
  color: var(--fg-black-muted);
}
.ParagraphText[data-object-type='project'] {
  color: var(--fg-white-muted);
}
.ParagraphText[data-align='left'] {
  text-align: left;
}
.ParagraphText[data-align='right'] {
  text-align: right;
}
@media (max-width: 480px) {
  .ParagraphText {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ParagraphText {
    width: 100%;
  }
}
.Label[data-object-shade='dark'] {
  color: var(--fg-black);
}
.Label[data-object-shade='light'] {
  color: var(--fg-white);
}
.Label[data-object-type='bright'] {
  opacity: 1;
}
.Label[data-object-type='muted'] {
  opacity: 0.5;
}
.Label[data-object-size='xs'] {
  font-size: 12px;
}
.Label[data-object-size='s'] {
  font-size: 14px;
}
.Label[data-object-size='m'] {
  font-size: 16px;
}
.Label[data-object-size='l'] {
  font-size: 18px;
}
.Label[data-object-size='xl'] {
  font-size: 20px;
}
.TextParagraph p {
  margin-bottom: 10px;
}
.ContactItem {
  margin-top: 0;
  margin-bottom: -10px;
}
.ContactItem a {
  color: var(--fg-white);
}
.ContactItem:hover:before {
  content: '—';
  color: var(--fg-white);
}
@media (max-width: 480px) {
  .ContactItem {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 481px) and (max-width: 768px) {
  .ContactItem {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.HeaderBar {
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
}
.HeaderBar > div {
  padding-bottom: 2.5%;
}
.HeaderBar .contentbar {
  display: flex;
  justify-content: flex-end;
}
.Project {
  padding: 1.5%;
  padding-left: 5%;
  padding-right: 5%;
  border-top: 1px solid var(--bg-black);
  transition: var(--all-transition);
}
.Project .project-info-bar {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.Project:last-child {
  border-bottom: 1px solid var(--bg-black);
}
.Project:hover {
  background-color: var(--bg-black);
  color: var(--fg-white);
}
.open {
  background-color: var(--bg-black) !important;
  color: var(--fg-white) !important;
}

.FooterBar {
  background-color: var(--bg-black);
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.FooterBar > div {
  padding-bottom: 2.5%;
}
.FooterBar .LogoType {
  color: var(--fg-white);
}
.Grid {
  margin: 2rem;
  height: 100%;
}
.Grid .item {
  margin: 4rem;
}
@media (max-width: 480px) {
  .Grid .item {
    margin: 0;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .Grid .item {
    margin: 0;
  }
}
.GridItem {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.GridItem .item-image {
  flex: 5;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.GridItem .item-text {
  flex: 3;
  display: flex;
  align-items: flex-start;
}
.GridItem .item-text h2,
.GridItem .item-text h3,
.GridItem .item-text h4 {
  font-weight: normal;
}
.GridItem .item-text p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  line-height: 20px;
}
.GridItem .item-text p a {
  color: #fff !important;
}
@media (max-width: 480px) {
  .GridItem {
    display: block;
  }
  .GridItem .item-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .GridItem .item-text {
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
  }
  .GridItem .item-text p {
    font-size: 14px;
    line-height: 20px;
  }
  .GridItem .item-text h2,
  .GridItem .item-text h3,
  .GridItem .item-text h4 {
    font-size: 18px;
    font-weight: normal;
    text-transform: lowercase !important;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .GridItem {
    display: block;
  }
  .GridItem .item-image {
    display: block;
  }
  .GridItem .item-text {
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
  }
  .GridItem .item-text p {
    opacity: 0.6;
    font-size: 14px;
    line-height: 20px;
  }
  .GridItem .item-text h2,
  .GridItem .item-text h3,
  .GridItem .item-text h4 {
    font-size: 22px;
  }
}
.ProjectDescription {
  color: var(--fg-white);
}
table,
td {
  margin: 0;
}

@media (max-width: 480px) {
  .PF {
    display: none !important;
    visibility: hidden;
  }
}
@media (max-width: 481px) and (max-width: 768px) {
  .PF {
    display: none;
  }
}

.ProjectFooter {
  margin-top: 20px;
  margin-bottom: 40px;
}
.ProjectFooter a {
  color: #fff !important;
  text-decoration: underline;
}
.ProjectFooter h2,
.ProjectFooter h3,
.ProjectFooter h4 {
  font-size: 14px !important;
  font-weight: normal !important;
}
.ProjectFooter .project-footer-title {
  padding: 10px;
  border: 1px solid rgba(225, 255, 255, 0.2);
}
.ProjectFooter .shaded {
  padding: 20px;
}
.ProjectFooter .shaded p {
  opacity: 0.6;
}
.Header {
  margin: 5%;
}
.Footer {
  background-color: var(--bg-black);
  padding: 5%;
}

