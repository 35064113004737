.ProjectDescription {
  color: var(--fg-white);

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 481px) and (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 769px) and (max-width: 1024px) {
  }

  // L - High Res
  @media (min-width: 1025px) and (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
