.Label {
  &[data-object-shade='dark'] {
    color: var(--fg-black);
  }
  &[data-object-shade='light'] {
    color: var(--fg-white);
  }

  &[data-object-type='bright'] {
    opacity: 1;
  }
  &[data-object-type='muted'] {
    opacity: 0.5;
  }

  &[data-object-size='xs'] {
    font-size: 12px;
  }
  &[data-object-size='s'] {
    font-size: 14px;
  }
  &[data-object-size='m'] {
    font-size: 16px;
  }
  &[data-object-size='l'] {
    font-size: 18px;
  }
  &[data-object-size='xl'] {
    font-size: 20px;
  }

  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
