.ParagraphText {
  font-size: var(--main-text-regular-14);
  font-family: var(--main-text-regular);
  line-height: var(--paragraph-line-heigth);
  width: 50%;

  &[data-object-type='regular'] {
    color: var(--fg-black-muted);
  }
  &[data-object-type='project'] {
    color: var(--fg-white-muted);
  }

  &[data-align='left'] {
    text-align: left;
  }
  &[data-align='right'] {
    text-align: right;
  }

  // XS - Phone
  @media (max-width: 480px) {
    width: 100%;
  }

  // S - Tablet
  @media (max-width: 768px) {
    width: 100%;
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
