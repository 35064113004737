.GridItem {
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .item-image {
    flex: 5;
    display: flex;
    //border: 1px solid lime;
    align-items: flex-start;
    justify-content: center;
  }

  .item-text {
    flex: 3;
    display: flex;
    align-items: flex-start;

    h2,
    h3,
    h4 {
      font-weight: normal;
    }

    p {
      a {
        color: #fff !important;
      }
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      line-height: 20px;
    }
    //justify-content:center;
  }

  // XS - Phone
  @media (max-width: 480px) {
    display: block;

    .item-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-text {
      margin-top: 10px;
      margin-bottom: 20px;
      display: block;

      p {
        font-size: 14px;
        line-height: 20px;
      }
      h2,
      h3,
      h4 {
        font-size: 18px;
        font-weight: normal;
        text-transform: lowercase !important;
      }
    }
  }

  // S - Tablet
  @media (min-width: 481px) and (max-width: 768px) {
    display: block;

    .item-image {
      display: block;
    }

    .item-text {
      margin-top: 10px;
      margin-bottom: 20px;
      display: block;

      p {
        opacity: 0.6;
        font-size: 14px;
        line-height: 20px;
      }
      h2,
      h3,
      h4 {
        font-size: 22px;
      }
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
