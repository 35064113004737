/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  @each $ct, $cd in $color-types {
    /*! #{$cd} */
    @each $style in $colors {
      $type: map-get($style, 'type');
      /*! #{$type} */
      $shades: rootColor($type, $ct, $style);
      @each $shade, $value in $shades {
        #{$shade}: #{$value};
      }
    }
  }

  /** FONT **/
  @each $entry in $fonts {
    $type: map-get($entry, 'type');
    $q: quote($type);
    //--#{$type}: '#{$type}';
    --#{$type}: '#{$q}';
  }

  /** FONT-SIZES **/
  @each $style in $fonts {
    $type: map-get($style, 'type');
    @each $s in map-get($style, 'sizes') {
      $v: $type + '-' + $s;
      $sx: pxToRem($s);
      --#{$v}: #{$sx};
    }
  }

  --all-transition: all 0.4s ease-in-out;
}
