.FooterBar {
  background-color: var(--bg-black);
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;

  & > div {
    padding-bottom: 2.5%;
  }

  .LogoType {
    color: var(--fg-white);
  }

  // XS - Phone
  @media (max-width: 480px) {
  }

  // S - Tablet
  @media (max-width: 768px) {
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
