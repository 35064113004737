.Grid {
  margin: 2rem;
  height: 100%;

  .item {
    margin: 4rem;
  }

  // XS - Phone
  @media (max-width: 480px) {
    .item {
      margin: 0;
    }
  }

  // S - Tablet
  @media (min-width: 481px) and (max-width: 768px) {
    .item {
      margin: 0;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
